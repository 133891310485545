import * as bootstrap from 'bootstrap'
import GLightbox from 'glightbox'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules';
import Lenis from 'lenis'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger)


// Tooltips
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

//Preloader
document.addEventListener("DOMContentLoaded", function() {
  setTimeout(() => {
    var fancyboxLoading = document.getElementById('overlay-preload');
    fancyboxLoading.style.display = 'none';
  },);
});

//Glightbox
document.addEventListener('DOMContentLoaded', () => {
  if (typeof GLightbox !== 'undefined') {
    const lightbox = GLightbox({
      loop: true,
      zoomable: false
    });
  }
});


//Actual Year
const d = new Date();
let year = d.getFullYear();
document.getElementById("year").innerHTML = year;


//Scroll to top

let mybutton = document.getElementById("top-btn");

window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

mybutton.onclick = function() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
};


//contact animation
 var link = document.querySelector('.contact-link');
 var icon = document.querySelector('.contact-icon');

 link.addEventListener('mouseover', function() {
   icon.style.transform = 'rotate(45deg)';
 });

 link.addEventListener('mouseout', function() {
   icon.style.transform = 'rotate(0deg)';
 });


//SWIPER portfolio////////////////////////////////////

  // nastavení
  var swiper = new Swiper(".mySwiper", {
    modules: [Navigation, Pagination],
    enabled: true,
    slidesPerView: 1,
    spaceBetween: 65,
    centeredSlides: true,
    grabCursor: true,
    parallax: true,
  
    keyboard: {
      enabled: true,
    },
  
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
      clickable: true,
    },
  
    breakpoints: {
      1200: {
        enabled: true,
        slidesPerView: 1.3,
        spaceBetween: 65,
      },
    },
  
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  
    on: {
      slideChange: function() {
        gsap.from(this.slides[this.activeIndex].querySelector('.port-img'), {
          x: "40%",
          opacity: 0,
          duration: 0.8,
          scarb: 1,
          ease: "power2.out"
        });
      },
      init: function() {
        gsap.from(this.slides[this.activeIndex].querySelector('.port-img'), {
          x: "40%",
          opacity: 0,
          duration: 0.8,
          scarb: 1,
          ease: "power2.out"
        });
      }
    }
  });



  //SWIPER reference//

  // nastavení
  var swiper = new Swiper(".refSwiper", {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 32,
    grabCursor: true,
    parallax: true,

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    keyboard: {
      enabled: true,
    },

    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
    },
  });



////////////// LENIS

const lenis = new Lenis();

lenis.on('scroll', ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 1000);
});

gsap.ticker.lagSmoothing(0);


///////////// GSAP

document.querySelectorAll('.animate-up').forEach(element => {
  gsap.from(element, {
    y: 100,
    opacity: 0,
    duration: 1,
    ease: "power3.out",
    scrollTrigger: {
      trigger: element,
      start: "top bottom-=100",
      toggleActions: "play none none reverse"
    }
  });
});


// MENU//
var menu = document.querySelector('.btn-open');
let menuOpen = false;

ScrollTrigger.create({
  start: 'top -80',
  end: 99999,
  toggleClass: {className: 'sticky-menu--scrolled', targets: '.sticky-menu'}
});

var tl = gsap.timeline({
  paused: true,
  onComplete: () => menuOpen = true,
  onReverseComplete: () => menuOpen = false
})

tl.to(".overlay", {
  right: 0,
  duration: 0.3,
  ease: "power2.inOut"
})

tl.from(".ul-menu li", {
  x: 100,
  duration: 0.3,
  stagger: 0.3,
  opacity: 0,
  ease: "back.out(1.7)"
}, "<")

tl.from(".ul-blog li", {
  y: 100,
  duration: 0.5,
  opacity: 0,
  ease: "back.out(1.7)"
}, "-=0.2")

tl.from(".social", {
  y: 50,
  duration: 0.3,
  opacity: 0,
  ease: "power2.out"
}, "-=0.3")

menu.addEventListener("click", function(e) {
  e.preventDefault();
  const logo = document.querySelector('.logo');
  
  if (menuOpen) {
    tl.reverse();
    menu.classList.remove('btn-close1');
    menu.classList.add('btn-open');
    logo.style.display = 'block';
  } else {
    tl.play();
    menu.classList.remove('btn-open');
    menu.classList.add('btn-close1');
    logo.style.display = 'none';
  }
});


// Close menu when clicking links
document.querySelectorAll('.wrap-nav a').forEach(link => {
  link.addEventListener('click', (e) => {
    e.preventDefault();
    const href = link.getAttribute('href');
    
    tl.reverse().eventCallback('onReverseComplete', () => {
      menu.classList.remove('btn-close1');
      menu.classList.add('btn-open');
      menuOpen = false;
      
      if (href.startsWith('#')) {
        // Handle same-page anchor links
        const target = document.querySelector(href);
        if (target) {
          lenis.scrollTo(target);
        }
      } else {
        // Handle external page navigation
        window.location.href = href;
      }
    });
  });
});

// MENU END //


//images//

gsap.to(".parallax-bg", {
  yPercent: 40,
  scale: 1.2,
  ease: "none",
  scrollTrigger: {
    trigger: ".parallax-img",
    start: "top bottom",
    end: "bottom top",
    scrub: 1.5,
    toggleActions: "play reverse play reverse"
  }
});



//Buttons //
// Button hover animation
document.querySelectorAll('.btn1').forEach(button => {
  button.addEventListener('mouseenter', () => {
    gsap.to(button.querySelector('.btn-bg'), {
      x: '0%',
      duration: 0.4,
      ease: 'power2.out'
    });
  });

  button.addEventListener('mouseleave', () => {
    gsap.to(button.querySelector('.btn-bg'), {
      x: '-101%',
      duration: 0.4,
      ease: 'power2.out'
    });
  });
});


